import {
    goodsPageSeckill,
    timeList
} from "@/api/seckill"

import CountDown from "vue2-countdown"

import { tree } from "@/api/goods/goodscategory"

export default {
    name: "dailyDrops",
    components: {
        CountDown
    },
    data: () => {
        return {
            classifyList: [],
            categoryShow: 0,
            slectedId: 0,
            value: "Recommend",
            options: [
                {
                    label: "Recommend",
                    value: 0
                }, {
                    label: "Price Low To High",
                    value: 1
                }, {
                    label: "Price High To Low",
                    value: 2
                }
            ],
            dropsList: [],
            col: 5,
            pageSize: 40,
            currentPage: 1,
            total: 0,
            order: "sale_num",
            sort: "",
            categoryId: 0,
            seckillTimeMachine: {
                currentTime: 0,
                startTime: 0,
                endTime: 0
            },
            tiemCodeList: { "code": 0, "message": "操作成功", "data": { "list": [{ "id": 2, "name": "每日秒杀", "seckill_start_time": 3601, "seckill_end_time": 86341, "seckill_start_time_show": "01:00:01", "seckill_end_time_show": "23:59:01" }] }, "timestamp": 1646967261 },
            loading: true,
            currency: localStorage.getItem("strCurXSoyego"),
            imgTo: "",
            todayTitle: "",
            tomorrowTitle: ""
        }
    },
    watch: {
		$route: "fetchData"
    },
    created() {
        this.getCountDown();
        this.getTree();
        this.getTimeList();
    },
    mounted() {
    },
    computed: {
        // itemWidth() {
        //     return ((document.body.clientWidth - 112 - 100) / 5)  //计算宽度
        // },
        // gutterWidth() {
        //     // let dom = (document.documentElement.clientWidth - 112 - 100) / 5;
        //     return (100 / 4)	//计算x轴方向margin(y轴方向的margin自定义在css中即可)
        // }
    },
    methods: {
        fetchData() {
            this.$forceUpdate();
        },
        countDownS_cb() {},
		countDownE_cb() {
			this.discountText = "Activity ended"
		},
        getCountDown() {
            let date = new Date();
            // let year = date.getFullYear();
            let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            this.todayTitle = month + '/' + day;

            let date1 = new Date() + 1;
            // console.log(date1)
        },
        categoryClick(id) {
            this.categoryId = id;
            this.currentPage = 1;
            this.loading = true;
            this.getTimeList();
        },
        selectChange() {
            if (this.value == 1) {
                this.order = "discount_price";
                this.sort = "asc";
                this.getTimeList();
            } else if (this.value == 2) {
                this.order = "discount_price";
                this.sort = "desc";
                this.getTimeList();
            } else if(this.value == 0) {
                this.order = "sale_num";
                this.sort = "";
                this.getTimeList();
            }
            this.$forceUpdate();
            // this.fetchData();
        },
        format(val) {
            return val === 100 ? 'Sold Out' : val + '%' + 'Sold';
        },
        scroll(scrollData) {
            // console.log(scrollData)
            this.categoryShow = 1;
            if(scrollData.diff <= 500){
                if(this.dropsList.length > 0){
                    this.loadmore();
                }
            }
        },
        switchCol(col) {
            // this.col = col
            // console.log(this.col)
        },
        getTree() {
            tree({
                level: 1
            }).then((res) => {
                if (res.code == 0 && res.data) {
                    this.classifyList = res.data;
                }
            }).catch((err) => {
                this.$message.error(err.message)
            })
        },
        // 触底事件
        loadmore(index) {
            this.currentPage = this.currentPage + 1;
            this.categoryShow = 1;
            if(this.dropsList.length > 0){
                this.getTimeList();
            }
        },
        mouseRight(id) {
            localStorage.setItem('productDetailsDropSoyego', true);
            this.imgTo = "/productDetails?sku_id=" + id;
        },
        detailsClick(id) {
            localStorage.setItem('productDetailsDropSoyego', true);
            const blank = this.$router.resolve({path: '/productDetails', query: {sku_id: id}})
            window.open(blank.href,'_blank')
        },
        handleCurrentChange(val) {
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0

            this.currentPage = val
            this.loadRight = true
            this.getDropsList()         
        },
        getDropsList(id) {
            goodsPageSeckill({
                page_size: this.pageSize,
                page: this.currentPage,
                order: this.order,
                sort: this.sort,
                seckill_id: id,
                site_id: 0,
                category_id: this.categoryId,
                barterRate: localStorage.getItem("countrySoyego")
            }).then(res => {
                // res = this.dropsCodeList;
                if (res.code == 0 && res.data) {
                    this.total = res.data.count;
                    // if (this.currentPage == 1) {
                        this.dropsList = res.data.list;
                        for (let i = 0; i < res.data.list.length; i++) {
                            if(typeof(this.dropsList[i].goods_image) == "string"){
                                this.dropsList[i].goods_image = this.dropsList[i].goods_image.split(",");
                            }
                        }
                    // } else {
                    //     let data = [];
                    //     for (let i = 0; i < res.data.list.length; i++) {
                    //         if(typeof(res.data.list[i].goods_image) == "string"){
                    //             res.data.list[i].goods_image = res.data.list[i].goods_image.split(",");
                    //         }
                    //         data[i] = res.data.list[i];
                    //     }
                    //     this.dropsList = this.dropsList.concat(data);
                    // }
                    this.loading = false;
                }
            }).catch(err => {
                this.loading = false;
                this.$message.warning(err.message);
            });
        },
        getTimeList() {
            timeList().then(res => {
                // res = this.tiemCodeList;
                if (res.code == 0 && res.data) {
                    let time = new Date(res.timestamp * 1000);
                    let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds();
                    res.data.list.forEach((v, k) => {
                        if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
                            let seckillId = v.id;
                            this.getDropsList(seckillId);

                            let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
                            this.seckillTimeMachine = {
                                currentTime: res.timestamp,
                                startTime: res.timestamp,
                                endTime: endTime
                            }
                        }
                    })
                }
            }).catch(err => {
                this.$message.error(err.message)
            })
        }
    }
}
