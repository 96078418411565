<template>
    <div class="dailyDrops">
        <div class="dailyDropsMain">
            <!-- <div class="dailyDrops-title">
                <span>Home /</span>
                <span>Daily Drops</span>
            </div> -->
            <div class="dailyDrops-banner">
                <h5>Flash Sale</h5>
                <div class="time">
                    
                    <!-- :dayTxt="'天'" :hourTxt="'小时'" :minutesTxt="'分钟'" :secondsTxt="'秒'"    -->
                    <count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime" :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"> </count-down>
                </div>
                <!-- <p>End of</p> -->
            </div>
            <div class="dailyDrops-main">
                <div class="main-nav">
                    <span v-for="(item, index) in classifyList" :key="index" :class="item.category_id == slectedId ? 'ative' : ''" @click="slectedId = item.category_id;categoryClick(item.category_id)">{{ item.category_name }}</span>
                </div>
                <div class="main-text">
                    <div class="text">
                        <span>{{total}} Product</span>
                        <p>Flash sale is a limited-time event that may offer deeply promotional discounted items with limited quantities. Offer good while supplies last.</p>
                    </div>
                    <div>
                        <el-select v-model="value" placeholder="Select" @change="selectChange">
                            <el-option v-for="item in options" :key="item" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main-list" v-loading="loading">
                    <!-- :width="itemWidth" :gutterWidth="gutterWidth" -->
                    <div class="main-list-item">
                        <div class="list">
                            <div class="item" v-for="(item, index) in dropsList" :key="index">
                                 <div class="item-img">
                                    <div class="img" @click="detailsClick(item.id)">
                                        <router-link :to="imgTo" @contextmenu.native="mouseRight(item.id)">
                                            <!-- crossorigin="anonymous" -->
                                            <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                        </router-link>
                                    </div>
                                    <div class="img-text">
                                        <p>SAVE</p>
                                        <span v-if="item.seckill_price - item.price < 0">{{ (((item.price - item.seckill_price) / item.price) * 100).toFixed(0)  }}%</span>
                                    </div>
                                </div>
                                <div class="item-title">
                                    <span>{{ item.goods_name }}</span>
                                </div>
                                <div class="item-main">
                                    <div class="item-text">
                                        <div>
                                            <span>from</span>
                                            <h5>{{ currency }}{{ item.seckill_price }}</h5>
                                            <s>{{ currency }}{{ item.price }}</s>
                                        </div>
                                        <p>Save {{ currency }}{{ (item.price - item.seckill_price).toFixed(2) }}</p>
                                    </div>
                                    <div class="item-sold">
                                        <button @click="detailsClick(item.id)" :class="item.percentage == '100' ? 'btn100' : ''">BUY NOW</button>
                                    </div>   
                                </div>                     
                            </div>
                        </div>
                    </div>
                    <!-- <waterfall :col="col" :data="dropsList" @loadmore="loadmore" @scroll="scroll" :isTransition="true">
                        <template>
                            <div class="cell-item item" v-for="(item, index) in dropsList" :key="index" style="cursor: pointer">
                                <div class="item-img" @click="detailsClick(item.id)">
                                    <div class="img">
                                        <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;width: 100%;height: 100%;"></el-image>
                                    </div>
                                    <div class="img-text">
                                        <span>-{{ (((item.price - item.seckill_price) / item.price) * 100).toFixed(0)  }}%</span>
                                    </div>
                                </div>
                                <div class="item-title">
                                    <span>{{ item.goods_name }}</span>
                                </div>
                                <div class="item-text">
                                    <div>
                                        <span>from</span>
                                        <h5>{{ currency }}{{ item.seckill_price }}</h5>
                                        <s>{{ currency }}{{ item.price }}</s>
                                    </div>
                                    <p>Save {{ currency }}{{ (item.price - item.seckill_price).toFixed(2) }}</p>
                                </div>
                                <div class="item-sold">
                                    <el-progress :text-inside="true" :stroke-width="18" :percentage="item.percentage" :format="format"></el-progress>
                                    <button @click="detailsClick(item.id)" :class="item.percentage == '100' ? 'btn100' : ''">BUY NOW</button>
                                </div>
                            </div>
                        </template>
                    </waterfall> -->
                </div>
                <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="40" layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>

        <!-- <div class="end">
            <span>End</span>
        </div> -->
    </div>
</template>

<style>
.dailyDrops .vue-waterfall .vue-waterfall-column{
    width: calc((100% - 100px) / 5) !important;
    padding-right: 25px;
}
.dailyDrops .vue-waterfall .vue-waterfall-column:last-child{
    padding-right: 0;
}
.dailyDrops .count-down span {
    padding: 0 8px;
    font-size: 30px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #330000;
    background: #f8f9fb;
}
.dailyDrops .count-down i {
    font-size: 30px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #f8f9fb;
    padding: 0 10px;
}
.dailyDrops .count-down i:last-child {
    display: none;
}

.about-content {
    background: #f7f9fa;
}
.dailyDrops .dailyDropsMain .el-input__inner {
    border-radius: 0;
}
.dailyDrops .dailyDropsMain .item .el-progress-bar__innerText {
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
    font-family: Montserrat,SF-UI;
    font-weight: 400;
    color: #330000;
}
.dailyDrops .dailyDropsMain .item .el-progress-bar__inner {
    background: linear-gradient(90deg, #ffe363, #ffca63);
}
</style>
<style lang="scss" scoped>
@import "./css/dailyDrops.scss";
</style>

<script>
import dailyDrops from "./js/dailyDrops.js"

export default {
    name: "dailyDrops",
    components: {},
    mixins: [dailyDrops]
}
</script>